import React from 'react'

const About = (props) => {
    return (
        <section className="wpo-about-section section-padding" id="about">
            <div className="container">
                <div className="wpo-about-content">
                    <div className="about-title">
                        <h2>Jerry Hepperle</h2>
                        <span>(Full Stack Developer)</span>
                    </div>
                    <p>Hello everyone, I am a full stack web Developer. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Pretium etiam consequat sit sit egestas dui gravida mattis. Tincidunt leo
                        sit vivamus varius enim, scelerisque pharetra viverra nibh. Tellus maecenas lacus, tristique
                        libero, quis. Fames tempor, platea risus aliquam in a vitae lobortis pellentesque.</p>
                    <div className="wpo-about-content-bottom">
                        <div className="wpo-about-content-bottom-left">
                            <h2>7+</h2>
                            <span>Years of
                                Experience</span>
                        </div>
                        <div className="wpo-about-content-bottom-right">
                            <p>Pretium etiam consequat sit sit egestas dui gravida mattis. Tincidunt leo sit vivamus
                                varius enim, scelerisque pharetra viverra nibh. Tellus maecenas lacus, tristique.
                                Fames tempor, platea risus aliquam in a vitae.</p>
                            <div className="wpo-about-content-bottom-right-items">
                                <div className="wpo-about-content-bottom-right-item">
                                    <h3>205</h3>
                                    <p>Project Complete</p>
                                </div>
                                <div className="wpo-about-content-bottom-right-item">
                                    <h3>112</h3>
                                    <p>Happy Client</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;