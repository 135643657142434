import React, {Fragment} from 'react';
import BackToTop from '../../components/backToTop/backToTop';
import HomeWrapper from '../../components/HomeWrapper/HomeWrapper';

const HomePage =() => {
    return(
        <Fragment>
            <HomeWrapper/>
            <BackToTop/>
        </Fragment>
    )
};
export default HomePage;